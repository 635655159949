<script lang="ts">
	import ExternalLink from 'lucide-svelte/icons/external-link'
	import Globe from 'lucide-svelte/icons/globe'
	import Newspaper from 'lucide-svelte/icons/newspaper'

	import { Button } from '$lib/components/ui/button'
	import type { ChainConfig } from '$lib/constants/chains'
	import Discord from '~icons/simple-icons/discord'
	import GitHub from '~icons/simple-icons/github'
	import Reddit from '~icons/simple-icons/reddit'
	import Telegram from '~icons/simple-icons/telegram'
	import Twitter from '~icons/simple-icons/x'
	import YouTube from '~icons/simple-icons/youtube'

	export let type: keyof ChainConfig['socials']
	export let link: string
	export let size = '22'
</script>

<Button aria-label={type} href={link} rel="noopener" size="icon" target="_blank" variant="outline">
	{#if type === 'website'}
		<Globe {size} />
	{:else if type === 'twitter'}
		<Twitter height={size} width={size} />
	{:else if type === 'discord'}
		<Discord height={size} width={size} />
	{:else if type === 'telegram'}
		<Telegram height={size} width={size} />
	{:else if type === 'github'}
		<GitHub height={size} width={size} />
	{:else if type === 'reddit'}
		<Reddit height={size} width={size} />
	{:else if type === 'blog'}
		<Newspaper {size} />
	{:else if type === 'youtube'}
		<YouTube height={size} width={size} />
	{:else}
		<ExternalLink />
	{/if}
</Button>
