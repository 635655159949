import type { ChainConfig } from '$lib/constants/chains'

const SOCIALS_ORDER: (keyof ChainConfig['socials'])[] = [
	'website',
	'twitter',
	'discord',
	'telegram',
	'reddit',
	'youtube',
	'github',
	'blog',
]

export const getSocials = (
	socials: ChainConfig['socials'],
	types: (keyof ChainConfig['socials'])[] = []
) => {
	const socialsArray = SOCIALS_ORDER.reduce(
		(acc, type) => {
			const linkOrLinks = socials[type]

			if (Array.isArray(linkOrLinks)) {
				return [
					...acc,
					...linkOrLinks.map(link => ({
						type,
						link,
					})),
				]
			} else if (typeof linkOrLinks === 'string') {
				return [
					...acc,
					{
						type,
						link: linkOrLinks,
					},
				]
			}
			return acc
		},
		<
			{
				type: keyof ChainConfig['socials']
				link: string
			}[]
		>[]
	)

	if (Array.isArray(types) && types.length > 0) {
		return socialsArray.filter(({ type }) => types.includes(type))
	}

	return socialsArray
}
